/** @jsx jsx */
import React, { Component } from 'react'
import { default_theme as theme } from '../emotion/theme'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { includes, split, last } from 'lodash'
import { InlineIcon } from './layout/InlineIcon'
import { Separator } from './layout/Separator'
import { base64FileInfoList } from '../actions/file_info'
import { Document, Page } from 'react-pdf'
import BlueButton from './layout/BlueButton'
import WrappingBusyMask from './WrappingBusyMask'
import { clientManager } from '../actions/client_manager'
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocumentPlayer extends Component {

    constructor(props) {
        super(props)
        this.state = { file_info_id: null,
                       num_pages: null,
                       current_page: 1 }
    }
    
    componentDidMount() {
        const { dispatch, file_info_id } = this.props
        if ( file_info_id ) {
            dispatch(base64FileInfoList.ensureObjectLoaded(file_info_id))
            this.setState({ file_info_id: file_info_id })
        }
    }

    componentDidUpdate() {
        const { dispatch, file_info_id } = this.props
        if ( file_info_id && this.state.file_info_id !== file_info_id ) {
            this.setState({ file_info_id: file_info_id,
                            current_page: 1})
        }
        if ( file_info_id ) {
            dispatch(base64FileInfoList.ensureObjectLoaded(file_info_id))
        }
    }

    onPreviousPage = () => {
        let { current_page } = this.state
        current_page -= 1
        if ( current_page < 1 ) {
            current_page = 1
        }
        this.setState({current_page: current_page})
    }

    onNextPage = () => {
        let { current_page, num_pages } = this.state
        current_page += 1
        if ( current_page > num_pages ) {
            current_page = num_pages
        }
        this.setState({current_page: current_page})
        window.scroll(0, 0)
    }
    
    onEnd = () => {
        const { onDocumentFinished } = this.props
        if ( onDocumentFinished ) {
            onDocumentFinished()
        }
    }

    onRenderReady = ({numPages}) => {
        this.setState({num_pages: numPages})
    }

    renderDocument(document, navigator) {
        const { document_as_base64, file_info_id } = this.props
        const { num_pages, current_page } = this.state
        if ( ! document_as_base64.base64 ) {
            return null
        }

        const width = Math.min(window.screen.width, 1108)
        console.log("pdf width", width)
        
        return (
            <div css={document_container_style}>
              <Document ref={file_info_id}
                        loading="..."
                        file={`data:application/pdf;base64,${document_as_base64.base64}`}
                        onLoadSuccess={this.onRenderReady}
              >
                <Page pageNumber={current_page} width={width} />
              </Document> 
            </div>
        )
    }
    
    renderDocumentAsPdf = (document) => {
        const { num_pages, current_page } = this.state
        const is_loading = !num_pages
        return (
            <div>
              <div css={loading_wrapper_container_style}>
                <WrappingBusyMask is_loading={is_loading}>
                </WrappingBusyMask>
              </div>
              { this.renderDocument(document) }
              { ! is_loading && 
                <div css={nav_style}>
                  <CustomNavigation page={current_page} num_pages={num_pages}
                                    handlePrevClick={this.onPreviousPage} handleNextClick={this.onNextPage}
                                    handleDoneClick={this.onEnd} />
                </div>
              }
            </div>
        )
    }

    renderDocumentAsPdfSlideshow = (document) => {
        return this.renderDocumentAsPdf(document)
    }
    
    render() {
        const { document, document_as_base64 } = this.props
        return (
            <div css={container}>
              { document_as_base64 && document.document_type === 'pdf' && this.renderDocumentAsPdf(document) }
              { document_as_base64 && document.document_type === 'pdf_slideshow' && this.renderDocumentAsPdfSlideshow(document) }
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const file_info_id = props.document.document.id
    const document_as_base64 = base64FileInfoList.getObject(file_info_id)
    return {
        file_info_id,
        document_as_base64
    }
}

export default connect(mapStateToProps)(DocumentPlayer)

const CustomNavigation = props => {
    const { page, num_pages, handlePrevClick, handleNextClick, handleDoneClick } = props
    const is_done = page === num_pages
  return (
    <div css={[custom_navigation, title_subheading_style]}>
    
    { page <= 1 &&
      <div css={[navigation_tile, navigation_tile_left]}>
        <InlineIcon icon_name="caret-left" variant="white" as_block={true} size_variant="2em" disable_margins={true} />
      </div>
    }
    { page > 1 &&
      <div css={[navigation_tile, navigation_tile_left, on_hover_style]}>
        <InlineIcon icon_name="caret-left" onClick={handlePrevClick} as_block={true} variant="white" size_variant="2em" disable_margins={true} />
      </div>
    }
    
      <Separator variant="w2" />
      <div css={[navigation_tile,
                 is_done ? done_style : null,
                 is_done ? css`background-color: white;` : null]}>
        { page < num_pages && 
          <div css={navigation_page_of_style}>
            {page} of {num_pages}
          </div>
        }
        { is_done && 
          <div css={navigation_done_style} onClick={handleDoneClick}>
            Done
          </div>
        }
      </div>
      <Separator variant="w2" />
      <>
      { page >= num_pages &&
        <div css={[navigation_tile, , navigation_tile_right]}>
          <InlineIcon icon_name="caret-right" variant="white" as_block={true} size_variant="2em" disable_margins={true}/>
        </div>
      }
      { page < num_pages &&
        <div css={[navigation_tile, , navigation_tile_right, on_hover_style]}>
          <InlineIcon icon_name="caret-right" onClick={handleNextClick} as_block={true} variant="white" size_variant="2em" disable_margins={true} />
        </div>
      }
        </>
      </div>
    )
}


const container = css`
display: relative;
`

const loading_wrapper_container_style = css`
position: absolute;
height: 100%;
width: 100%;
`

const image = css`
max-width: 100%;
max-height: 100%;
`

const custom_navigation = css`
  display: flex;
  justify-content: center;
  position: relative;
`

const title_subheading_style = css`
font-weight: 400;
display: flex;
font-size: 16px;
`

const document_container_style = css(theme.mq({
    position: "relative",
    overflow: "auto",
    width: ["1108px", "100%"],
    minHeight: ["320px", "320px"],

    ".react-pdf__Document": {
        display: "flex",
        justifyContent: "center"
    }
}))

const nav_style = css(theme.mq({
  position: "absolute",
  bottom: "10px",
  margin: "auto",
  width: ["1108px", "100%"],
  color: "#fff",
  display: "flex-end",
  justifyContent: "center"
}))

const navigation_tile = css`
  background-color: rgb(0,0,0,0.5);
  padding: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
`

const navigation_tile_right = css`
  border-radius: 0px 2px 2px 0px;
`

const navigation_tile_left = css`
  border-radius: 2px 0px 0px 2px;
`

const navigation_page_of_style = css`
  white-space: nowrap;
  font-size: 24px;
`

const done_style = css`
  cursor: pointer;
  font-size: 24px;
  color: black;
  &:hover {
  transform: scale(1.1);
  }  
`

const navigation_done_style = css`
  /* height: 20px;
  * width: 50px; */
  text-align: center;
`

const on_hover_style = css`
  &:hover {
  transform: scale(1.1);
  }
`
